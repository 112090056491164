/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type CatalogStatusUpdateSchema = {
    status: CatalogStatusUpdateSchema.status;
};
export namespace CatalogStatusUpdateSchema {
    export enum status {
        ON_PAUSE = 'on_pause',
        ACTIVE = 'active',
    }
}

