/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type SupplierOrderUpdateSchema = {
    status: SupplierOrderUpdateSchema.status;
    dateDelivery?: string;
    warehouseDeliveredDate?: string;
    deliveryCost?: number;
    /**
     * Номер УПД, который был сформирован в рамках данного подзаказа поставщика
     */
    updNumber?: string;
    reasonId?: number;
};
export namespace SupplierOrderUpdateSchema {
    export enum status {
        ACCEPT_SHIPPED = 'accept_shipped',
        DECLINE = 'decline',
        ACCEPT_WAREHOUSE_DELIVERY = 'accept_warehouse_delivery',
    }
}

