/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DeclinedItemResp } from './DeclinedItemResp';
import type { market__usecases__schemas__order_out__LimitResp } from './market__usecases__schemas__order_out__LimitResp';
import type { SuborderResp } from './SuborderResp';
export type OrderResp = {
    id: number;
    number: string;
    status: OrderResp.status;
    source: string;
    divisible: boolean;
    delivery: string;
    clientId: number;
    userId: string;
    recipientName?: string;
    recipientPhone?: string;
    beId: number;
    basisId: number;
    subdivisionId?: number;
    erpDistribution: OrderResp.erp_distribution;
    costVat: number;
    costNet: number;
    deliveryCost?: number;
    deliveryVatCost?: number;
    costWithVat: number;
    costWithDelivery: number;
    costWithVatAndDelivery: number;
    summaryVat?: number;
    isDeliveryInboundRequired?: boolean;
    isReadyOrLater?: boolean;
    purchaseOrder?: string;
    isPurchaseOrderEditable?: boolean;
    createTs: string;
    registrationDate?: string;
    erpOrderId?: number;
    limits: Array<market__usecases__schemas__order_out__LimitResp>;
    declinedModels: Array<DeclinedItemResp>;
    items: Array<SuborderResp>;
};
export namespace OrderResp {
    export enum status {
        DRAFT = 'draft',
        ACTIVE = 'active',
        ACTION_REQUIRED = 'action_required',
        DONE = 'done',
        CANCELED = 'canceled',
    }
    export enum erp_distribution {
        NOT_ACTIVE = 'NOT_ACTIVE',
        IN_PROGRESS = 'IN_PROGRESS',
        FAIL = 'FAIL',
        SUCCESS = 'SUCCESS',
        PARTIALLY_SUCCEED = 'PARTIALLY_SUCCEED',
    }
}

