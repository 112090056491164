/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DeliveryDateSource } from './DeliveryDateSource';
import type { schemas__client_v2__BusinessEntityIntegrationType } from './schemas__client_v2__BusinessEntityIntegrationType';
import type { schemas__client_v2__LimitsUploadType } from './schemas__client_v2__LimitsUploadType';
import type { TTNFormat } from './TTNFormat';
import type { v2__api__schemas__business_entity__BEOptions } from './v2__api__schemas__business_entity__BEOptions';
import type { v2__api__schemas__business_entity__RequisitesRetrieveSchema } from './v2__api__schemas__business_entity__RequisitesRetrieveSchema';
export type BERetrieveSchemaV2 = {
    id: number;
    clientId: number;
    clientName?: string;
    businessEntityType?: string;
    requisites?: v2__api__schemas__business_entity__RequisitesRetrieveSchema;
    isDeleted: boolean;
    isDeliveryInboundRequired: boolean;
    isPackingListRequired: boolean;
    ttnFormatInventory: TTNFormat;
    ttnFormatNonInventory: TTNFormat;
    name: string;
    legalEntity?: string;
    extCode?: string;
    okpo: string;
    autoSolverAddRelatedModels?: boolean;
    autoErpOrderCreate?: boolean;
    autoAcceptErpSupplierOrder?: BERetrieveSchemaV2.auto_accept_erp_supplier_order;
    addRelatedModels?: boolean;
    erpLimitCheck?: boolean;
    autoRepeatErpSolver?: boolean;
    autoRepeatErpSolverTimeH?: number;
    workPhone?: string;
    legalAddress?: string;
    actualAddress?: string;
    ogrn?: string;
    website?: string;
    mpLimitsCheck?: boolean;
    ociAutoAddRelatedModels?: boolean;
    ociAddRelatedModels?: boolean;
    mpOrderMaxCost?: number;
    mpOrderMinCost?: number;
    erpOrderMaxCost?: number;
    erpOrderMinCost?: number;
    integrationType?: schemas__client_v2__BusinessEntityIntegrationType;
    limitsUploadType?: schemas__client_v2__LimitsUploadType;
    deliveryOrganizationDays?: number;
    deliveryDateSource?: DeliveryDateSource;
    orderApproveDays?: number;
    maxErpSuborderDeliveryPercent?: number;
    pfmId?: number;
    pfmName?: string;
    allowSupplierCargoSpace: boolean;
    inn: string;
    kpp: string;
    paymentAccount: string;
    rcbic: string;
    correspondentAccount: string;
    bankBranch?: string;
    bankInn: string;
    bankKpp: string;
    createTs?: string;
    updateTs?: string;
    options?: v2__api__schemas__business_entity__BEOptions;
};
export namespace BERetrieveSchemaV2 {
    export enum auto_accept_erp_supplier_order {
        TRUE = 'true',
        FALSE = 'false',
        CUSTOM = 'custom',
    }
}

